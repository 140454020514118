import React from 'react';
import Icon from './Icon';
import StyledTextField from './TextField.style';

export const ClearButton = (p) => (
  <span {...p}>
    <Icon
      width="14"
      height="13"
      d="m7 5.086 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414L7 7.914l-4.95 4.95L.636 11.45l4.95-4.95-4.95-4.95L2.05.136 7 5.086Z"
    />
  </span>
);

// eslint-disable-next-line react/display-name
const TextField = React.forwardRef(
  (
    {
      prefix,
      clearable,
      alwaysShowClear,
      onClear,
      onChange,
      value,
      hasError,
      input,
      children,
      icon,
      inputSelector,
      containerSelector,
      rawEvents,
      Component = StyledTextField,
      overridePlaceholder,
      placeholder,
      componentProps = {},
      suffix,
      disabled,
      ...p
    },
    ref
  ) => {
    return (
      <Component
        className={(value ? 'has-value ' : '') + (hasError ? 'has-error ' : '')}
        prefix={prefix}
        clearable={clearable}
        alwaysShowClear={alwaysShowClear}
        icon={icon}
        inputSelector={inputSelector}
        containerSelector={containerSelector}
        {...componentProps}
      >
        {!!suffix && (
          <div className="suffix-container">
            <div className="before-suffix">{value}</div>
            {suffix}
          </div>
        )}
        {input ? (
          typeof input === 'function' ? (
            input({
              type: 'text',
              ...p,
              placeholder: overridePlaceholder || placeholder,
              value: value,
              onChange: rawEvents
                ? onChange
                : (e) => onChange?.(e.target.value),
              ref: ref,
            })
          ) : (
            input
          )
        ) : (
          <input
            type="text"
            {...p}
            placeholder={overridePlaceholder || placeholder}
            value={value}
            onChange={rawEvents ? onChange : (e) => onChange?.(e.target.value)}
            ref={ref}
            disabled={disabled}
          />
        )}
        {children}
        {clearable && (
          <ClearButton
            className="clear"
            onClick={() => {
              onClear?.(value);
              onChange?.('');
            }}
          />
        )}
      </Component>
    );
  }
);

export default TextField;
