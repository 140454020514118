import React, { useMemo, useRef, useEffect, useState } from 'react';
import StyledSlideUp from './SlideUp.style';

const SlideUp = ({
  tag = 'div',
  innerTag: T = 'div',
  children,
  outerRef: _outerRef = null,
  innerRef: _innerRef = null,
  open,
  fixedSpeed = true,
  className = '',
  absolute = false,
  ...p
}) => {
  // Get references to elements
  const outerRef = _outerRef || useRef();
  const innerRef = _innerRef || useRef();
  const currentV = useRef();
  const [addedClasses, setAddedClasses] = useState({});

  const measure = useMemo(() => {
    if (!outerRef.current || !innerRef.current) return () => {};
    return (e) => {
      if (!outerRef.current || !innerRef.current) return;
      const speed = e?.detail?.speed;
      const newV = innerRef.current.clientHeight;
      // Make sure we don't update the DOM too often
      if (newV === currentV.current) return;
      currentV.current = newV;
      outerRef.current.style.setProperty('--inner-height', `${newV}px`);
      if (speed !== undefined) {
        outerRef.current.style.setProperty(
          '--transition-duration',
          `${speed}ms`
        );
      } else {
        outerRef.current.style.removeProperty('--transition-duration');
      }
      ///calc(100ms + 0.5ms * var(--inner-size))
      outerRef.current.style.setProperty(
        '--fixed-speed-transition-duration',
        `${100 + 0.5 * newV}ms`
      );
    };
  }, [outerRef.current, innerRef.current]);
  measure();

  // Track the size of the inner component
  useEffect(() => {
    // Listen on window resize
    window.addEventListener('resize', measure);
    window.addEventListener('measure-slideups', measure);

    // Check it every second
    const interval = setInterval(measure, 1000);

    // Check it right now
    measure();

    return () => {
      window.removeEventListener('resize', measure);
      window.removeEventListener('measure-slideups', measure);
      clearInterval(interval);
    };
  }, [measure]);

  useEffect(() => {
    if (!outerRef.current) return;
    // Enable animations on next tick, so if the menu is open on load it doesn't start with an animation
    setTimeout(
      () => outerRef.current?.style.setProperty('--transition', '1'),
      0
    );
  }, [outerRef.current]);

  // use an effect so that we can add transitional classes and
  // open class at the same time, to remove a one-tick glitch
  useEffect(() => {
    // add transitional classes if possible
    const cstyle = getComputedStyle(outerRef.current);
    const enabled = cstyle.getPropertyValue('--transition') !== '0';
    const duration = cstyle.getPropertyValue('--transition-duration');
    const mObj = duration.match(/^([0-9.]+)ms$/);
    if (!enabled || !mObj) {
      // not possible to add transitional classes, only add open
      setAddedClasses((p) => ({ ...p, open }));
    } else {
      // add transitional classes and open class
      const ms = Number(mObj[1]);
      const className = open ? 'opening' : 'closing';
      setAddedClasses((p) => ({ ...p, [className]: true, open }));
      const timeout = setTimeout(() => {
        setAddedClasses((p) => ({ ...p, [className]: false }));
      }, ms);
      return () => {
        setAddedClasses((p) => ({ ...p, [className]: false }));
        clearTimeout(timeout);
      };
    }
  }, [open]);
  return (
    <StyledSlideUp
      as={tag}
      ref={outerRef}
      className={
        className +
        (absolute ? ' absolute' : '') +
        (fixedSpeed ? ' fixed-speed' : '') +
        ` ${Object.entries(addedClasses)
          .reduce((p, [k, v]) => {
            if (v) p.push(k);
            return p;
          }, [])
          .join(' ')}`
      }
      {...p}
    >
      <T className="inner" ref={innerRef}>
        {children}
      </T>
    </StyledSlideUp>
  );
};
export default SlideUp;
