import React from 'react';
import styled from '@emotion/styled';

// eslint-disable-next-line react/display-name
const Component = React.forwardRef(({ as: T = 'div', ...props }, ref) => (
  <T {...props} ref={ref} />
));

export default styled(Component)`
  position: relative;
  padding: 0;

  /** these are set from JS */
  --inner-height: 0px;
  --fixed-speed-transition-duration: 100ms;
  --transition: 0;

  --transition-duration: 250ms;

  height: 0;
  &.open {
    height: var(--inner-height);
  }

  &:not(.open),
  &.closing,
  &.opening {
    // when open, we want to show overflowing content, but not
    // during animations or when closed
    overflow: hidden;
  }

  transition: height calc(var(--transition-duration) * var(--transition))
    ease-in-out;

  pointer-events: none;
  & > .inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    pointer-events: none;
    & > * {
      pointer-events: initial;
    }
  }
  &.fixed-speed {
    --transition-duration: var(--fixed-speed-transition-duration);
  }
  &.slide-down {
    & > .inner {
      transition: transform calc(var(--transition-duration) * var(--transition))
        ease-in-out;
      transform: translateY(calc(0px - var(--inner-height)));
    }
    &.open > .inner {
      transform: translateY(0);
    }
  }

  &.absolute {
    position: absolute;
    & > .inner {
      position: relative;
    }
  }
`;
